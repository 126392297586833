import React from "react";
import { Grid, Box, Flex } from "@chakra-ui/react";
import useDimensions from "react-cool-dimensions";
import { motion } from "framer-motion";
import ImageModal from "./image-modal";
import { useSearchParams } from "react-router-dom";

export const ProductImages = ({ images }) => {
  const { observe, width } = useDimensions();
  const columns = Math.ceil(width / 200);
  const [search, setSearch] = useSearchParams();
  const [current, setCurrent] = React.useState(search.get("image") || null)
  return (
    <Box as="section" id="images" ref={observe}>
      {current !== null && (
        <ImageModal
          setCurrent={setCurrent}
          image={images[parseInt(current) % images.length]}
          prevIndex={images[current - 1] !== undefined ? current - 1 : null}
          nextIndex={images[current + 1] !== undefined ? current + 1 : null}
        />
      )}
      <Grid templateRows="masonry" templateColumns={["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"]} rowGap="8" columnGap="4">
        {images.map((img, i) => (
          <Box cursor="pointer" key={i}>
            <motion.img
              layout
              onClick={() => setCurrent(i)}
              width="200"
              alt={img.alt || `Bild ${i}`}
              src={img.file.thumb.url}
              srcSet={img.file.thumb_webp && `${img.file.thumb_webp.url} 200w`}
            />
          </Box>
        ))}
      </Grid>
    </Box>
  );
};
